<template>
  <div class="form-main animated fadeInUp">
    <div class="col">
      <el-input
        type="number"
        v-show="isPhone === '1'"
        class="user-input"
        placeholder="请输入手机号"
        maxlength="11"
        v-model.trim="userTel"
        @focus="focus"
        @blur="blur('phone')"
        autocomplete="str"
      >
      </el-input>
      <el-input
        v-show="isPhone === '0'"
        class="user-input"
        placeholder="请输入账号"
        maxlength="20"
        v-model.trim="user"
        @focus="focus"
        @blur="blur('user')"
        autocomplete="str"
        :readonly="readonly"
      >
      </el-input>
      <div class="img-div" :class="{ active: !isUser }">
        <img
          class="prepend"
          src="../../../assets/image/login/icon2.png"
          v-show="isUser"
        />
        <img
          class="prepend"
          src="../../../assets/image/login/icon1.png"
          v-show="!isUser"
        />
      </div>
      <div class="tips" v-show="userTxt">{{ userTxt }}</div>
    </div>
    <div class="col" v-show="isPhone === '0'">
      <el-input
        type="password"
        class="user-input"
        placeholder="请输入密码"
        maxlength="20"
        v-model.trim="password"
        :readonly="readonly"
        @focus="focusPass"
        @blur="blurPass('password')"
      >
      </el-input>
      <div class="img-div" :class="{ active: !isPassword }">
        <img
          class="prepend"
          src="../../../assets/image/login/icon4.png"
          v-show="isPassword"
        />
        <img
          class="prepend"
          src="../../../assets/image/login/icon3.png"
          v-show="!isPassword"
        />
      </div>
      <div class="tips" v-show="passwordTxt">{{ passwordTxt }}</div>
    </div>
    <div class="col" v-show="isPhone === '1'">
      <el-input
        type="password"
        class="user-input"
        placeholder="请输入短信验证码"
        maxlength="20"
        v-model.trim="password"
        @focus="focusPass"
        @blur="blurPass('msgcode')"
        autocomplete="str"
      >
      </el-input>
      <div class="append-div">
        <div
          class="code"
          @click="getMsgCode"
          :class="codeBtnStyle ? 'active' : ''"
        >
          <span v-if="codeBtnStyle">{{ isCountdown }}</span
          >{{ codeTxt }}
        </div>
      </div>
      <div class="img-div" :class="{ active: !isPassword }">
        <img
          class="prepend"
          src="../../../assets/image/login/icon6.png"
          v-show="isPassword"
        />
        <img
          class="prepend"
          src="../../../assets/image/login/icon5.png"
          v-show="!isPassword"
        />
      </div>
      <div class="tips" v-show="msgcodeTxt">{{ msgcodeTxt }}</div>
    </div>
    <el-checkbox v-model="checked" @change="reserveInfo">{{
      isPhone === "0" ? "记住用户名" : "记住手机号"
    }}</el-checkbox>
    <el-button type="primary" @click="loginNumber">登录</el-button>
  </div>
</template>

<script>
import {
  postCodeLoginApi,
  postUidLoginApi,
  postSendMessageApi,
  getUserInfoApi,
} from "@/request/api";
import { mapState, mapMutations } from "vuex";
let testPhone =
  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
export default {
  name: "loginForm",
  props: {
    isPhone: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      user: "",
      userTel: "",
      password: "",
      checked: false,
      readonly: true,
      isUser: true,
      isPassword: true,
      userPlaceholder: "请输入账号",
      userTxt: "",
      passwordTxt: "",
      msgcodeTxt: "",
      code: "",
      codeTxt: "获取验证码",
      isCountdown: 60,
      codeBtnStyle: false,
      setT: null,
      setTimeout: null,
    };
  },
  watch: {
    isCountdown(newVal) {
      if (!newVal) {
        this.codeBtnStyle = false;
        clearInterval(this.setT);
        this.setT = null;
        this.codeTxt = "获取验证码";
        this.isCountdown = 60;
      }
    },
  },
  beforeDestroy() {
    if (this.setT) {
      clearInterval(this.setT);
      this.setT = null;
    }
    if (this.setTimeout) {
      clearInterval(this.setTimeout);
      this.setTimeout = null;
    }
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.checked = localStorage.getItem("checked") || false;
    this.setReadonly();
    this.getName();
  },
  methods: {
    ...mapMutations("user", ["SET_USERINFO"]),

    setReadonly() {
      this.setTimeout = setTimeout(() => {
        this.readonly = false;
      }, 500);
    },
    keyupEnter() {
      document.onkeyup = () => {
        if (window.event.keyCode === 13) {
          this.loginNumber();
        }
      };
    },
    async getUserInfoApi() {
      const { code, results } = await getUserInfoApi();
      if (code === 0) {
        let userInfoObj = JSON.parse(localStorage.getItem("userInfoObj")) || "";
        if (userInfoObj.id != results.id) {
          localStorage.setItem("userInfoObj", JSON.stringify(results));
          this.SET_USERINFO(results);
          localStorage.removeItem("farmInfo");
          localStorage.removeItem("farmId");
        } else if (!userInfoObj) {
          localStorage.removeItem("farmId");
          localStorage.removeItem("farmInfo");
        }
        if (localStorage.getItem("farmId")) {
          this.$router.push({
            name: "index",
          });
        } else {
          this.$emit("openWindow");
        }
      }
    },
    async loginNumber() {
      if (this.passwordTxt || this.userTxt || this.msgcodeTxt) return;
      let loginInfo = {};
      if (this.isPhone === "1") {
        if (this.checked) {
          localStorage.setItem("USERTEL", this.userTel);
          localStorage.setItem("checked", true);
        } else {
          localStorage.removeItem("USERTEL");
          localStorage.removeItem("checked");
        }
        loginInfo = {
          phone: this.userTel,
          msgcode: this.password,
          host: "https://data.darsing.net/#/",
        };
        const { code, results } = await postCodeLoginApi(loginInfo);
        if (code === 0) {
          localStorage.setItem("darsingToken", results.token);
          localStorage.setItem("exp", results.exp);

          this.getUserInfoApi();
          this.$notify({
            title: "提示",
            message: "登录成功！",
            type: "success",
          });
        }
      } else {
        if (this.checked) {
          localStorage.setItem("USERNAME", this.user);
          localStorage.setItem("checked", true);
        } else {
          localStorage.removeItem("USERNAME");
          localStorage.removeItem("checked");
        }
        loginInfo = {
          uid: this.user,
          password: this.password,
          host: "https://data.darsing.net/#/",
        };
        const { code, results } = await postUidLoginApi(loginInfo);
        if (code === 0) {
          localStorage.setItem("darsingToken", results.token);
          localStorage.setItem("assignManorId", results.manor_id);

          localStorage.setItem("exp", results.exp);
          this.$notify({
            title: "提示",
            message: "登录成功！",
            type: "success",
          });
          this.getUserInfoApi();
        }
      }
    },
    getName() {
      let nameMsg = localStorage.getItem("USERNAME") || "";
      let nameTel = localStorage.getItem("USERTEL") || "";
      if (nameMsg) {
        this.user = nameMsg;
        this.checked = true;
      }
      if (nameTel) {
        this.userTel = nameTel;
        this.checked = true;
      }
    },
    reserveInfo(e) {
      if (e) {
        localStorage.setItem("USERNAME", this.user);
        localStorage.setItem("checked", true);
      } else {
        localStorage.removeItem("USERNAME");
        localStorage.removeItem("checked");
      }
    },
    async getMsgCode() {
      if (this.codeBtnStyle) return;
      if (!testPhone.test(this.userTel)) return;
      const res = await postSendMessageApi({
        phone: this.userTel,
        tid: 3,
      });
      if (res.code === 0) {
        this.codeBtnStyle = true;
        this.codeTxt = "秒后重新获取";
        this.setT = setInterval(() => {
          this.isCountdown--;
        }, 1000);
        this.$notify({
          title: "提示",
          message: "已发送",
        });
      }
    },
    focus() {
      this.isUser = false;
    },
    blur(type) {
      this.isUser = true;
      if (type === "user") {
        if (!this.user) {
          this.userTxt = "账号不能为空";
        } else {
          this.userTxt = "";
        }
      } else if (type === "phone") {
        if (!this.userTel) {
          this.userTxt = "手机号不能为空";
        } else if (!testPhone.test(this.userTel)) {
          this.userTxt = "请输入正确手机号";
        } else {
          this.userTxt = "";
        }
      }
    },
    focusPass() {
      this.isPassword = false;
    },
    blurPass(type) {
      this.isPassword = true;
      if (type === "password") {
        if (!this.password) {
          this.passwordTxt = "密码不能为空";
        } else if (this.password.length < 6 || this.password.length > 18) {
          this.passwordTxt = "密码为 6 到 18 位字符";
        } else {
          this.passwordTxt = "";
        }
      } else if (type === "msgcode") {
        if (!this.password) {
          this.msgcodeTxt = "验证码不能为空";
        } else if (this.password.length != 6) {
          this.msgcodeTxt = "验证码为 6 位字符";
        } else {
          this.msgcodeTxt = "";
        }
      }
    },
  },
};
</script>

<style lang="less">
.form-main {
  padding: 53px 0 0 0;
  display: flex;
  flex-direction: column;
  .el-input-group__append,
  .el-input-group__prepend {
    border: none;
    width: 54px;
    height: 54px;
    background: #f8fafb;
  }
  .col {
    width: 100%;
    position: relative;
    margin-bottom: 28px;
    .append-div {
      height: 44px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      .code {
        padding: 0 16px;
        font-size: 14px;
        color: #3e90e5;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          width: 1px;
          height: 21px;
          background: #e8e8e8;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
        &.active {
          cursor: default;
          color: #999;
        }
      }
    }
    .img-div {
      width: 40px;
      height: 40px;
      background: #f8fafb;
      position: absolute;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #dcdfe6;
      border-right: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      .prepend {
        width: 24px;
        height: 24px;
      }
    }
    .tips {
      width: 100%;
      height: 40px;
      line-height: 40px;
      color: #f56c6c;
    }
    .user-input {
      padding-left: 40px;
      .el-input__inner {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &:focus {
          border-color: #3e90e5;
        }
        &::-webkit-input-placeholder {
          color: #999;
          font-size: 14px;
        }
      }
    }
  }

  .el-checkbox {
    margin: 6px 0 25px;
    color: #919191;
    font-size: 14px;
  }
}
</style>
